import React from "react";
import {
  FaFacebookF,
  FaYoutube,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import { AiFillMail, AiFillPhone } from "react-icons/ai";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";

import logoWhite from "assets/logo/ic-logo-white.webp";

import "./Footer.css";
import useSendEvent from "hooks/useSendEvent";

export default function Footer() {
  const { sendEvent } = useSendEvent();
  return (
    <div className="relative overflow-hidden bg-[#16152F] text-white py-16 sm:py-24 lg:py-16 ">
      <div className="m2kfb"></div>
      <div className="mx-auto max-w-7xl px-5 lg:px-10 xl:px-10 2xl:px-10 mt-10">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 border-b pb-12 md:grid-cols-2 lg:max-w-none lg:grid-cols-4 sm:grid-c">
          <div className="max-w-xl lg:max-w-lg">
            <img
              src={logoWhite}
              alt="footerLogo"
              className="w-[200px] h-auto"
            />
            <p className="mt-4 text-lg leading-8">
              Interactive Cares is Bangladesh’s leading Upskilling & Job
              Placement Platform
            </p>
            <div className="mt-6 flex flex-col max-w-md gap-x-4">
              <h2 className="text-lg font-bold tracking-tight sm:text-lg">
                Follow Us
              </h2>

              <div className="flex space-x-4">
                <div className="border-white">
                  <a
                    href="https://www.facebook.com/interactivecares"
                    className="text-white  hover:text-gray-700"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaFacebookF
                      size={28}
                      className="border border-teal-300 bg-teal-500 rounded-sm p-1"
                      onClick={() => sendEvent("Facebook Icon", "home")}
                    />
                  </a>
                </div>
                <a
                  href="https://www.youtube.com/@interactivecares2026"
                  className="text-white  hover:text-gray-700"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaYoutube
                    size={28}
                    className="border border-teal-300 bg-teal-500 rounded-sm p-1"
                    onClick={() => sendEvent("Youtube Icon", "home")}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/interactivecares/posts/?feedView=all"
                  className="text-white  hover:text-gray-700"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedinIn
                    size={28}
                    className="border border-teal-300 bg-teal-500 rounded-sm p-1"
                    onClick={() => sendEvent("Linkedin Icon", "home")}
                  />
                </a>
                <a
                  href="https://www.instagram.com/interactive_cares/"
                  className="text-white  hover:text-gray-700"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram
                    size={28}
                    className="border border-teal-300 bg-teal-500 rounded-sm p-1"
                    onClick={() => sendEvent("Instagram Icon", "home")}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="">
            <div className="rounded-md ">
              {/* <CalendarDaysIcon className="h-6 w-6 text-black" aria-hidden="true" /> */}
            </div>
            <dt className="mb-5 font-semibold">Contact Us</dt>
            <div>
              <div className="flex gap-3 items-center my-2">
                <span>
                  <CiLocationOn />
                </span>
                <p>
                  1/E/2, Adabor, Mohammadpur, Behind Shompa Market, Dhaka 1207
                </p>
              </div>
              <div className="flex gap-3 items-center my-2">
                <span>
                  <AiFillMail />
                </span>
                <p>operations@interactivecares.com</p>
              </div>
              <div className="flex gap-3 items-center my-2">
                <span>
                  <AiFillPhone />
                </span>
                <p>01958622151</p>
              </div>
            </div>
          </div>

          <div className="flex items-start justify-start flex-col nd:items-center lg:items-center xl:items-center">
            <div className="rounded-md bg-white/5 ring-1 ring-white/10">
              {/* <CalendarDaysIcon className="h-6 w-6 text-black" aria-hidden="true" /> */}
            </div>
            <dt className="mb-5 font-semibold">Quick Links</dt>
            <ul className="flex flex-col gap-y-4">
              <li>
                <Link
                  to="/allcourses"
                  onClick={() => sendEvent("Courses", "home")}
                >
                  Courses
                </Link>
              </li>
              <li>
                {" "}
                <Link
                  to="/hiringpage"
                  onClick={() => sendEvent("Hire Talents", "home")}
                >
                  Hire Talents
                </Link>
              </li>
              <li>
                {" "}
                <Link
                  to="/affiliation"
                  onClick={() => sendEvent("Affiliation", "home")}
                >
                  Affiliation
                </Link>
              </li>
              <li>
                <Link to="/career" onClick={() => sendEvent("Career", "home")}>
                  Career
                </Link>
              </li>
            </ul>
          </div>
          <div className="flex flex-col items-start">
            <img
              src="https://interactivecaresbucket.s3.ap-southeast-1.amazonaws.com/main-frontend/assets/footerimage.webp"
              className="w-[200px] h-auto"
              alt="footer"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-5 justify-between mx-auto max-w-7xl mt-5 px-5 lg:px-10 xl:px-10 2xl:px-10">
        <div className="flex flex-col lg:flex-row gap-5">
          <Link
            to="/terms-and-conditions"
            onClick={() => sendEvent("terms-and-conditions", "home")}
          >
            <span>Terms of Use</span>
          </Link>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ background: "white" }}
          />
          <Link
            to="/privacy-policy"
            onClick={() => sendEvent("privacy-policy", "home")}
          >
            <span>Privacy Policy</span>
          </Link>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ background: "white" }}
          />
          <Link to="/report-issue">
            <span>Report an Issue</span>
          </Link>
        </div>
        <div>
          <span>© 2025 All Rights Reserved to Interactive Cares</span>
        </div>
      </div>
    </div>
  );
}
